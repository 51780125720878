import React, { useReducer } from "react"
import { menu, menuReducer } from "../components/menu/Actions"
import { alertTop, alertTopReducer } from "../components/alert/AlertTop"
import {
  alertBottom,
  alertBottomReducer,
} from "../components/alert/AlertBottom"
import { bag, bagReducer } from "../components/bag/Bag"
import { guide, guideReducer } from "../components/guide/Guide"
import { user, userReducer } from "../components/user/User"

export const Context = React.createContext()

const config = {
  breakpoint: 900,
  alertTopText:
    "CAMBIOS Y/O DEVOLUCIONES: HASTA 30 DÍAS DESDE REAPERTURA DE TIENDAS | AHORA TAMBIEN RECOJO EN TIENDA _ MAS INFO+",
  alertBottomText: "FREE SHIPPING POR COMPRAS DESDE S/49.90",
  isAlertTop: true,
  isAlertBottom: true,
  isWomen: true,
  bagProducts: [
    { id: 1, product: "Producto A" },
    { id: 2, product: "Producto B" },
  ],
}

const configReducer = (state, action) => {
  switch (action.type) {
    case "WOMEN": {
      return { ...state, isWomen: true }
    }
    case "MEN": {
      return { ...state, isWomen: false }
    }
    default:
      return state
  }
}

const global = { config, menu, alertTop, alertBottom, bag, guide, user }

const reducer = (global, action) => ({
  config: configReducer(global.config, action),
  menu: menuReducer(global.menu, action),
  alertTop: alertTopReducer(global.alertTop, action),
  alertBottom: alertBottomReducer(global.alertBottom, action),
  bag: bagReducer(global.bag, action),
  guide: guideReducer(global.guide, action),
  user: userReducer(global.user, action),
})

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, global)
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}
