import React, { useContext } from "react"
import { Context } from "../../utils/Context"
import Products from "../../templates/producto/productos.json"
import "./Guide.css"

const Guide = () => {
  const { dispatch } = useContext(Context)
  return (
    <div className="guide">
      <div className="guide-content">
        <div className="guide-header">
          <span className="guide-header-icon material-icons">square_foot</span>
          <div className="guide-title">GUÍA DE TALLAS (MUJERES)</div>
          <button className="guide-close" onClick={() => dispatch(guideHide())}>
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className="guide-main">
          <div className="guide-main-inner">
            <div className="guide-table">
              <picture>
                <img
                  className="guide-table-img"
                  src={Products[13].img}
                  alt="img"
                />
              </picture>
              <div className="guide-table-details">
                <div className="guide-table-title">
                  BLUSAS, POLOS, CHOMPAS, SWEATSHIRT, ABRIGOS, BLAZERS
                </div>
                <table className="guide-table-sizes">
                  <thead>
                    <tr>
                      <th>TALLA</th>
                      <th>CINTURA (CM)</th>
                      <th>CINTURA (CM)</th>
                      <th>CINTURA (CM)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>PS</td>
                      <td>82-86</td>
                      <td>59-64</td>
                      <td>85-90</td>
                    </tr>

                    <tr>
                      <td>S</td>
                      <td>82-86</td>
                      <td>59-64</td>
                      <td>85-90</td>
                    </tr>
                    <tr>
                      <td>M</td>
                      <td>82-86</td>
                      <td>59-64</td>
                      <td>85-90</td>
                    </tr>
                    <tr>
                      <td>L</td>
                      <td>82-86</td>
                      <td>59-64</td>
                      <td>85-90</td>
                    </tr>
                    <tr>
                      <td>XL</td>
                      <td>82-86</td>
                      <td>59-64</td>
                      <td>85-90</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const guide = {
  isGuide: false,
}

const guideReducer = (state, action) => {
  switch (action.type) {
    case "GUIDE_SHOW": {
      return { ...state, isGuide: true }
    }
    case "GUIDE_HIDE": {
      return { ...state, isGuide: false }
    }
    default:
      return state
  }
}

const guideShow = () => ({
  type: "GUIDE_SHOW",
})

const guideHide = () => ({
  type: "GUIDE_HIDE",
})

export { Guide as default, guide, guideReducer, guideShow, guideHide }
