import React, { useContext, useEffect } from "react"
import { Context } from "./Context"
import AlertTop, {
  alertTopShow,
  alertTopHide,
  alertTopText,
} from "../components/alert/AlertTop"
import AlertBottom, {
  alertBottomHide,
  alertBottomShow,
  alertBottomText,
} from "../components/alert/AlertBottom"
import Bag from "../components/bag/Bag"
import Guide from "../components/guide/Guide"
import User from "../components/user/User"

export default ({ children }) => {
  const { state, dispatch } = useContext(Context)
  useEffect(() => {
    state.config.isAlertTop
      ? dispatch(alertTopShow())
      : dispatch(alertTopHide())
    state.config.isAlertBottom
      ? dispatch(alertBottomShow())
      : dispatch(alertBottomHide())
    dispatch(alertTopText(state.config.alertTopText))
    dispatch(alertBottomText(state.config.alertBottomText))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {children}
      <div className="portal">
        {state.alertTop.isAlertTop && (
          <AlertTop text={state.alertTop.alertTopText} />
        )}
        {state.alertBottom.isAlertBottom && (
          <AlertBottom text={state.alertBottom.alertBottomText} />
        )}
        {state.bag.isBag && <Bag />}
        {state.guide.isGuide && <Guide />}
        {state.user.isUser && <User />}
      </div>
    </>
  )
}
