import React, { useContext } from "react"
import { Context } from "../../utils/Context"
import Button from "../button/Button"
import Products from "../../templates/producto/productos.json"
import "./Bag.css"

const Bag = () => {
  const { state, dispatch } = useContext(Context)
  return (
    <div className="bag">
      <div className="bag-header">
        <span className="bag-header-icon material-icons">shopping_bag</span>
        <div className="bag-title">BOLSA DE COMPRAS ({state.bag.bagCount})</div>
        <button className="bag-close" onClick={() => dispatch(bagHide())}>
          <span className="material-icons">close</span>
        </button>
      </div>
      {state.bag.bagCount ? (
        <div className="bag-main">
          <div className="bag-main-inner">
            {state.bag.bagProducts.map((item, i) => (
              <div className="card-product" key={i}>
                <picture>
                  <img
                    className="card-product-img"
                    src={Products[item.id].img}
                    alt="img"
                  />
                </picture>
                <div className="card-product-details">
                  <div className="card-product-title">{item.product}</div>
                  <div className="card-product-price-size">
                    <div className="card-product-price">S/. 249.90</div>
                    <div className="card-product-size">M</div>
                  </div>
                  <button
                    className="btn-product-delete"
                    onClick={() => {
                      dispatch(bagRemove(item.id))
                    }}
                  >
                    <span className="material-icons">delete</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="bag-main empty">Tu bolsa está vacía</div>
      )}
      {state.bag.bagCount ? (
        <div className="bag-footer">
          <div className="subtotal">Subtotal (1 producto): S/. 249.90</div>
          <Button className="btn-shop-now" block>
            COMPRAR AHORA
          </Button>
        </div>
      ) : (
        <div className="bag-footer">
          <Button className="btn-new-in" block outline>
            VER NEW IN
          </Button>
          <Button block>VER TODO ???</Button>
        </div>
      )}
    </div>
  )
}

const bag = {
  isBag: false,
  bagCount: 0,
  bagProducts: [],
}

const bagReducer = (state, action) => {
  switch (action.type) {
    case "BAG_SHOW": {
      return { ...state, isBag: true }
    }
    case "BAG_HIDE": {
      return { ...state, isBag: false }
    }
    case "BAG_ADD": {
      const newBagCount = state.bagCount + 1
      const newProduct = {
        id: newBagCount,
        product: action.product,
      }
      return {
        ...state,
        bagCount: newBagCount,
        bagProducts: [...state.bagProducts, newProduct],
      }
    }
    case "BAG_REMOVE": {
      const idx = state.bagProducts.findIndex(item => item.id === action.id)
      const products = Object.assign([], state.bagProducts)
      products.splice(idx, 1)
      return {
        ...state,
        bagCount: state.bagCount - 1,
        bagProducts: products,
      }
    }
    default:
      return state
  }
}

const bagShow = () => ({
  type: "BAG_SHOW",
})

const bagHide = () => ({
  type: "BAG_HIDE",
})

const bagAdd = product => {
  return {
    type: "BAG_ADD",
    product: product,
  }
}

const bagRemove = id => {
  return {
    type: "BAG_REMOVE",
    id: id,
  }
}

export { Bag as default, bag, bagReducer, bagShow, bagHide, bagAdd, bagRemove }
