export const menu = {
  isMenuOpen: false,
}

export const menuReducer = (state, action) => {
  switch (action.type) {
    case "MENU_TOGGLE": {
      return { ...state, isMenuOpen: !state.isMenuOpen }
    }
    default:
      return state
  }
}

export const menuToggle = () => ({
  type: "MENU_TOGGLE",
})
