import React, { useContext } from "react"
import { Link } from "gatsby"
import { Context } from "../../utils/Context"
import SocialIcon from "../social-icon/SocialIcon"
import Button from "../button/Button"
import ButtonLink from "../button/ButtonLink"
import "./User.css"

const User = () => {
  const { dispatch } = useContext(Context)
  return (
    <div className="user">
      <div className="user-header">
        <span className="user-header-icon material-icons">login</span>
        <div className="user-title">ACCEDE A TU CUENTA</div>
        <button className="user-close" onClick={() => dispatch(userHide())}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="user-main">
        <div className="user-main-inner">
          <form className="login">
            <div className="email-control">
              <input
                className="email-input"
                type="email"
                placeholder="Dirección de e-mail"
              ></input>
            </div>
            <div className="password-control">
              <input
                className="password-input"
                type="password"
                placeholder="Contraseña"
              ></input>
            </div>
            <Link to="/" className="new-pass">
              Restablecer contraseña
            </Link>
            <div className="keep-control">
              <input type="checkbox" id="keep" name="keep" value="ok" />
              <label htmlFor="keep">Mantener la sesión iniciada</label>
            </div>
            <Button className="btn-enter" block>
              ACCEDER
            </Button>
            <Button className="btn-fcbk-enter" block>
              <SocialIcon name="facebook" />
              <span className="fcbk-text">ACCEDE CON FACEBOOK</span>
            </Button>
          </form>
        </div>
      </div>
      <div className="user-footer">
        <div className="new">¿NUEVO EN KIDSMADEHERE?</div>
        <ButtonLink block outline url="/cuenta">
          REGÍSTRATE
        </ButtonLink>
      </div>
    </div>
  )
}

const user = {
  isUser: false,
}

const userReducer = (state, action) => {
  switch (action.type) {
    case "USER_SHOW": {
      return { ...state, isUser: true }
    }
    case "USER_HIDE": {
      return { ...state, isUser: false }
    }
    default:
      return state
  }
}

const userShow = () => ({
  type: "USER_SHOW",
})

const userHide = () => ({
  type: "USER_HIDE",
})

export { User as default, user, userReducer, userShow, userHide }
