// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-404-js": () => import("./../../../src/templates/404/404.js" /* webpackChunkName: "component---src-templates-404-404-js" */),
  "component---src-templates-bolsa-de-compras-bolsa-de-compras-js": () => import("./../../../src/templates/bolsa-de-compras/BolsaDeCompras.js" /* webpackChunkName: "component---src-templates-bolsa-de-compras-bolsa-de-compras-js" */),
  "component---src-templates-cambios-devoluciones-cambios-devoluciones-js": () => import("./../../../src/templates/cambios-devoluciones/CambiosDevoluciones.js" /* webpackChunkName: "component---src-templates-cambios-devoluciones-cambios-devoluciones-js" */),
  "component---src-templates-categoria-categoria-js": () => import("./../../../src/templates/categoria/Categoria.js" /* webpackChunkName: "component---src-templates-categoria-categoria-js" */),
  "component---src-templates-cuenta-cuenta-js": () => import("./../../../src/templates/cuenta/Cuenta.js" /* webpackChunkName: "component---src-templates-cuenta-cuenta-js" */),
  "component---src-templates-genero-genero-js": () => import("./../../../src/templates/genero/Genero.js" /* webpackChunkName: "component---src-templates-genero-genero-js" */),
  "component---src-templates-inicio-inicio-js": () => import("./../../../src/templates/inicio/Inicio.js" /* webpackChunkName: "component---src-templates-inicio-inicio-js" */),
  "component---src-templates-libro-reclamaciones-libro-reclamaciones-js": () => import("./../../../src/templates/libro-reclamaciones/LibroReclamaciones.js" /* webpackChunkName: "component---src-templates-libro-reclamaciones-libro-reclamaciones-js" */),
  "component---src-templates-privacidad-confidencialidad-privacidad-confidencialidad-js": () => import("./../../../src/templates/privacidad-confidencialidad/PrivacidadConfidencialidad.js" /* webpackChunkName: "component---src-templates-privacidad-confidencialidad-privacidad-confidencialidad-js" */),
  "component---src-templates-producto-producto-js": () => import("./../../../src/templates/producto/Producto.js" /* webpackChunkName: "component---src-templates-producto-producto-js" */),
  "component---src-templates-terminos-condiciones-terminos-condiciones-js": () => import("./../../../src/templates/terminos-condiciones/TerminosCondiciones.js" /* webpackChunkName: "component---src-templates-terminos-condiciones-terminos-condiciones-js" */),
  "component---src-templates-tiendas-tiendas-js": () => import("./../../../src/templates/tiendas/Tiendas.js" /* webpackChunkName: "component---src-templates-tiendas-tiendas-js" */)
}

