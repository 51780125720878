import React, { useContext } from "react"
import { Context } from "../../utils/Context"
import "./Alert.css"

const AlertBottom = ({ text = "" }) => {
  const { dispatch } = useContext(Context)
  return (
    <div className="alert alert-bottom">
      {text}
      <button className="btn-close" onClick={() => dispatch(alertBottomHide())}>
        <span className="material-icons">close</span>
      </button>
    </div>
  )
}

const alertBottom = {
  isAlertBottom: false,
  alertBottomText: "",
}

const alertBottomReducer = (state, action) => {
  switch (action.type) {
    case "ALERT_BOTTOM_SHOW": {
      return { ...state, isAlertBottom: true }
    }
    case "ALERT_BOTTOM_HIDE": {
      return { ...state, isAlertBottom: false }
    }
    case "ALERT_BOTTOM_TEXT": {
      return { ...state, alertBottomText: action.alertBottomText }
    }
    default:
      return state
  }
}

const alertBottomShow = () => ({
  type: "ALERT_BOTTOM_SHOW",
})

const alertBottomHide = () => ({
  type: "ALERT_BOTTOM_HIDE",
})

const alertBottomText = text => ({
  type: "ALERT_BOTTOM_TEXT",
  alertBottomText: text,
})

export {
  AlertBottom as default,
  alertBottom,
  alertBottomReducer,
  alertBottomShow,
  alertBottomHide,
  alertBottomText,
}
