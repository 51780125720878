import React from "react"
import { Link } from "gatsby"
import css from "./button.module.css"

export default ({ children, className, block, outline, url }) => {
  const _className = className ? className : ""
  const _block = block ? `${css.block}` : ""
  const _outline = outline ? `${css.outline}` : ""
  return (
    <Link
      className={`${css.btnLink} ${_className} ${_block} ${_outline}`}
      to={url}
    >
      {children}
    </Link>
  )
}
