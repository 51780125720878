import React, { useContext } from "react"
import { Context } from "../../utils/Context"
import "./Alert.css"

const AlertTop = ({ text = "" }) => {
  const { dispatch } = useContext(Context)
  return (
    <div className="alert alert-top">
      {text}
      <button className="btn-close" onClick={() => dispatch(alertTopHide())}>
        <span className="material-icons">close</span>
      </button>
    </div>
  )
}

const alertTop = {
  isAlertTop: false,
  alertTopText: "",
}

const alertTopReducer = (state, action) => {
  switch (action.type) {
    case "ALERT_TOP_SHOW": {
      return { ...state, isAlertTop: true }
    }
    case "ALERT_TOP_HIDE": {
      return { ...state, isAlertTop: false }
    }
    case "ALERT_TOP_TEXT": {
      return { ...state, alertTopText: action.alertTopText }
    }
    default:
      return state
  }
}

const alertTopShow = () => ({
  type: "ALERT_TOP_SHOW",
})

const alertTopHide = () => ({
  type: "ALERT_TOP_HIDE",
})

const alertTopText = text => ({
  type: "ALERT_TOP_TEXT",
  alertTopText: text,
})

export {
  AlertTop as default,
  alertTop,
  alertTopReducer,
  alertTopShow,
  alertTopHide,
  alertTopText,
}
